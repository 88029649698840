import { render, staticRenderFns } from "./DetailsJournalism.vue?vue&type=template&id=9d0e8fea&scoped=true&"
import script from "./DetailsJournalism.vue?vue&type=script&lang=js&"
export * from "./DetailsJournalism.vue?vue&type=script&lang=js&"
import style0 from "./DetailsJournalism.vue?vue&type=style&index=0&id=9d0e8fea&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d0e8fea",
  null
  
)

export default component.exports