<template>
  <div class="footer">
    <div class="bottom">
      <div class="conter">
        <div>
          <p>电话：028-85326217</p>
          <p style="margin-right: 53px">邮箱：Email:knot@scknot.com</p>
          <p>地址：成都市高新区天府三街吉泰路666号福年广场T2-1205</p>
        </div>
        <div>
          <p>
           © 2011-2021 成都卡恩特医疗科技有限公司 蜀ICP备11016462号-1
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    // 获取当前高度
    getHeight() {
      // 页面高度
    }
  },
  created() {}
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  // height: 100%;
  // background-color: #040c1b;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
  .bottom {
    padding-top: 4px;
    height: 113px;
    background-color: #040c1b;
    box-sizing: border-box;
    width: 100%;
    color: #fff;
    .conter {
      div:nth-child(1) {
        display: flex;
        font-size: 17px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        justify-content: center;
        // margin-top: 30px;
        p {
          margin-right: 17px;
        }
      }
      div:nth-child(2) {
        // display: flex;
        // justify-content: center;
        font-size: 13px;
        // text-align: center;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 13px;
        opacity: 0.28;
        p {
          // text-align: center;
          margin-left: 350px;
          span {
            display: inline-block;
            // padding-left: 20px;
            // margin-left: 10px;
          }
          span:first-child {
            padding-right: 30px;
          }
        }
      }
    }
  }
}
</style>
